import React from "react";
import {loadingSpin} from "./routingContainer";
import {AuthInitialized, Protected} from "./routingWrappers";
import {ApplicationUserRole} from "../../api";

const Main = React.lazy(() => import("../components/mainPage"));
const Login = React.lazy(() => import( "../components/loginPage"));
const Users = React.lazy(() => import("../applicationUser/applicationUserListContainer"));

const ReportingUsers = React.lazy(() => import("../reportingUser/reportingUserListContainer"));
const ReportingUserDetails = React.lazy(() => import("../reportingUser/reportingUserDetailsContainer"));

const Transactions = React.lazy(() => import("../transaction/transactionListContainer"));
const TransactionDetails = React.lazy(() => import("../transaction/transactionDetailsContainer"));

const ForgotPassword = React.lazy(() => import("../components/forgotPassword"));
const ForgotPasswordComplete = React.lazy(() => import("../components/forgotPasswordComplete"));
const Activate = React.lazy(() => import("../authentication/activate/activatePage"));

export const LoginPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Login />
</AuthInitialized>;

export const ForgotPasswordPage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <ForgotPassword />
</AuthInitialized>;

export const ForgotPasswordCompletePage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <ForgotPasswordComplete/>
</AuthInitialized>

export const ActivatePage = () => <AuthInitialized loadingIndicator={loadingSpin}>
    <Activate />
</AuthInitialized>;

export const MainPage = () => <Protected loadingIndicator={loadingSpin}>
    <Main />
</Protected>

export const UsersPage = () => <Protected
        loadingIndicator={loadingSpin}
        allowedForRoles={[ ApplicationUserRole.Admin, ApplicationUserRole.ReportingSoftware, ApplicationUserRole.DeviceAdmin ]}>
    <Users />
</Protected>


export const ReportingUsersPage = () => <Protected loadingIndicator={loadingSpin}>
    <ReportingUsers />
</Protected>

export const ReportingUserDetailsPage = () => <Protected loadingIndicator={loadingSpin}>
    <ReportingUserDetails />
</Protected>


export const TransactionsPage = () => <Protected loadingIndicator={loadingSpin}>
    <Transactions />
</Protected>

export const TransactionDetailsPage = () => <Protected loadingIndicator={loadingSpin}>
    <TransactionDetails />
</Protected>

